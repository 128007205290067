import * as React from "react";
import Layout from "../presentation/components/layout";
import Top from "../presentation/containers/top";
import Head from "../presentation/components/head";
import styled from "styled-components";

// markup
const IndexPage = () => {
  return (
    <>
      <Head description="Antyba合同会社オフィシャルサイト。企業情報やサービス、実績を掲載しています。" />
      <Layout>
        <Top />
      </Layout>
    </>
  );
};

export default IndexPage;
