import * as React from "react";
import styled from "styled-components";

const Top = () => {
  return (
    <>
      <TopImage />
      <Vision />
      <About />
      <Service />
    </>
  );
};

export default Top;

const TopImage = () => (
  <div style={{ textAlign: "center", width: "100%" }}>
    <img style={{ margin: "auto" }} src="/main.webp" />
  </div>
);

const Vision = () => (
  <Caption
    title="価値ある「おもしろい」で世界を笑顔にする"
    description={[
      "世界中に溢れるデジタルコンテンツの中から最適なコンテンツを選ぶことが難しい時代。",
      "「おもしろい」ことは当たり前に、本当に価値のあるデジタルコンテンツをあらゆる領域にお届けいたします。",
      "その先にある、人々の笑顔を作る「現実と仮想の融合社会」を目指します。",
    ]}
  />
);

const About = () => (
  <Caption
    title="About Antyba"
    description={[
      "Antyba.incは、デジタルテクノロジーを生かしたコンテンツ開発を中心に",
      "あらゆるお客様のご要望を叶えさせていただきます。",
    ]}
  />
);

const Service = () => (
  <div style={{ width: "100%", marginTop: "100px" }}>
    <CaptionHeader>Service</CaptionHeader>
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <ServiceContent
        title="アプリケーション開発"
        imgSrc="/app.webp"
        description={[
          "iPhone、Android、iPad などのフロントエンド及び、バックエンド開発・運用を行っております。",
        ]}
      />
      <ServiceContent
        title="WEB開発"
        imgSrc="/web.webp"
        description={[
          "webアプリ開発に関する各種業務（企画・要件定義・開発・テストなど）を行っております。",
        ]}
      />
      <ServiceContent
        title="インタラクティブシステム開発"
        imgSrc="interactive.webp"
        description={[
          "インタラクティブシステムに関する各種業務（企画・提案・コンテンツ制作・システム開発など）を行っております。",
        ]}
      />
    </div>
  </div>
);

const Caption = (props: { title: string; description: string[] }) => (
  <div style={{ width: "100%", marginTop: "100px" }}>
    <CaptionHeader>{props.title}</CaptionHeader>
    <div>
      {props.description.map((x, index) => (
        <CaptionDescription key={`${props.title}-${index}`}>
          {x}
        </CaptionDescription>
      ))}
    </div>
  </div>
);

const ServiceContent = (props: {
  title: string;
  imgSrc: string;
  description: string[];
}) => (
  <div
    style={{
      textAlign: "center",
      width: "300px",
      margin: "50px 30px 0px 30px",
    }}
  >
    <img src={props.imgSrc} style={{ width: "160px" }} />
    <div style={{ fontSize: "20px", fontWeight: 700, marginTop: "10px" }}>
      {props.title}
    </div>
    <div style={{ marginTop: "20px" }}>
      {props.description.map((x, index) => (
        <div
          style={{ fontSize: "14px", fontWeight: 400, lineHeight: 1.4 }}
          key={`${props.title}-${index}`}
        >
          {x}
        </div>
      ))}
    </div>
  </div>
);

const CaptionHeader = styled.div`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.15em;
  line-height: 2.5;
  text-align: center;
`;

const CaptionDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.15em;
  line-height: 2.5;
  text-align: center;
`;
